.loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid rgb(3, 239, 98);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
margin-top: auto;
margin-bottom: auto;
margin-right: 0.25vw;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 