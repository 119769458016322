:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background: #05192d00 !important;
}
code[class*="language-"] {
  font-size: 1em;
  line-height: 1.6;
  background: #05192d00 !important;
  color: white;
  box-shadow: -0.3em 0 0 0.3em #05192d, 0.3em 0 0 0.3em #05192d !important;
}
.cs-message--incoming .cs-message__content {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);

}
.cs-message--outgoing .cs-message__content {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);

}
.cs-message__content {
  font-size: .8em !important;
  color: #05192d !important;
  background-color: #d1dbe3 !important;
  line-height: 1.2rem;
  /* border: 1px solid #05192d; */
}
.cs-message--outgoing .cs-message__content-wrapper .cs-message__content {
  color: white !important;
  background-color: #114375 !important;
  /* border: 1px solid white; */
}
.cs-message-input__content-editor-wrapper {
  font-size: 12px !important;
  /* color: rgb(0, 0, 0) !important; */
  background-color: #114375 !important;
}

.cs-message-input__content-editor {
  font-size: 12px !important;
  /* color: rgb(0, 0, 0) !important; */
  background-color: #114375 !important;
  color: white !important;
}
.cs-button--send {
  color: #114375 !important;
  padding: 0.1em !important;
}

.cs-chat-container {
  background-color: rgba(243, 242, 242, 0.822) !important;
}
.cs-message-input {
  background-color: rgba(243, 242, 242, 0.822) !important;
}

.cs-message-list .cs-typing-indicator {
  width: 100%;
}
.cs-message-list .cs-typing-indicator {
  background-color: #fff0 !important;
}

.cs-typing-indicator__text {
  font-size: 14px;
  box-sizing: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: white !important;
  background-color: transparent;
  margin: 0 0 0 0.5em;
  -webkit-user-select: none;
  user-select: none;
}
.cs-message-input__content-editor[data-placeholder]:empty:before {
  color: white !important;
}
.messageInput {
  display: flex;
  justify-content: center;
  align-items: center;
  width: inherit;
  background-color: rgba(243, 242, 242, 0.822);
  padding-bottom: 8px;
}

.cs-chat-container .cs-message-input .cs-message-input__content-editor-wrapper:first-child {
  margin-left: 0.1rem;
}

.messageInput > div {
  /* margin-top: auto; */
  padding: 0.3em 0 0.3em 0em;
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(243, 242, 242, 0.822);
  margin-left: 10px;
}

.clearIcon:hover {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  background-color: rgba(157, 211, 178, 0.2);
  cursor: pointer;
}
/* .messageInput{
  border-top: solid 1px #d1dbe3 !important;
} */
.cs-chat-container .cs-message-input {
  border-top: solid 0px #d1dbe3 !important;
}

.codeEmbbed {
  margin-top: 10px;
  background-color: #05192d;
  color: #f8f8f2;
  padding: 10px;
  border-radius: 4px;
  font-family: "Courier New", Courier, monospace;
  /* font-size: 11px; */
  font-size: 1em;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(255, 255, 255);
  align-items: flex-start;
  justify-content: space-between;
}
.codeEmbbedBtn {
  border: 1px solid rgb(3, 239, 98);
  padding: 0px 7px 1px 0px;
  font-size: 10px;
  background-color: #05192d;
  color: #f8f8f2;
  border-radius: 4px;
  cursor: pointer;
  z-index: 1;
  margin-right: 0;
  margin-bottom: -44px;
  margin-left: auto;
  transition: background-color 0.3s;
}
.codeEmbbedBtn >svg {
  height: 30px;
  width: 29px;
}
.codeEmbbedBtn:hover{
  background-color: rgb(3, 239, 98);
}

.token.selector, .token.attr-name, .token.char, .token.builtin {
  color: #e9950c !important;;
}
.token.atrule, .token.attr-value, .token.keyword {
  color: #2e95d3 !important;;
}
.token.property, .token.tag, .token.boolean, .token.number, .token.constant, .token.symbol {
  color: #df3079 !important;;
}
.token.selector, .token.attr-name, .token.string, .token.char{
  color: #00a67d !important;; 
}
.token.operator, .token.entity, .token.url, .language-css .token.string, .token.variable, .token.inserted {
  color: white !important;;
}
.token.punctuation {
  color: #ffffff !important;
}
.token.function {
  color: #f22c3d !important;
}

@media only screen and (max-width: 408px) {
  .codeEmbbedBtn {
    border: 1px solid rgb(3, 239, 98);
    padding: 2px 4px;
    font-size: 7px;
    background-color: #05192d;
    color: #f8f8f2;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 0;
    margin-bottom: -30px;
    margin-left: auto;
    transition: background-color 0.3s;
}
.codeEmbbedBtn >svg {
  height: 20px;
  width: 20px;
}
}
@media only screen and (min-width: 582px) {
  .cs-message-list__scroll-wrapper>.cs-message, .cs-message-list__scroll-wrapper>.cs-message-group {
    max-width: 92%;
}
}