/* .textArea {
    height: 50vh !important;
    width: 100% !important;
    color: #05192d
} */
.utilityButtons > button{
border-radius: 7px;
background-color:  rgb(3, 239, 98);
border: 2px solid white;
height: 41px;
}

.utilityButtons > button:hover{
    background-color:  rgba(3, 239, 97, 0.541);
}
.utilityButtons{
    display: flex;
    flex-direction: row;
}
p{
    color: #05192d
}
