.image-size {
  width: 60vw;
    height: auto; 
  }
.draggable-image {
    position: absolute;
    border: 2px solid #05192d;
    max-width: fit-content;
    left: 24%;;
    z-index: 1;
  }
  
  .drag-handle {
    position: absolute;
    top: -10px;
    left: -10px;
    width: 20px;
    height: 20px;
    background-color: #03ef62;
    cursor: move;
  }
  
  .draggable-image img {
    pointer-events: none; /* Disable pointer events on the image to allow dragging */
    display: block; /* Ensure the image takes up the proper space */
    max-width: 100%; /* Make sure the image does not exceed the container's width */
    height: auto; /* Preserve the image's aspect ratio */
  }
  .remove-image-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: none;
    border: none;
    font-size: 16px;
    color: #ff0000;
    cursor: pointer;
  }
  
  