.scroll::-webkit-scrollbar {
  width: 8px;
  background-color: #f1f1f1;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.scroll::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.ps__rail-y:hover>.ps__thumb-y, .ps__rail-y:focus>.ps__thumb-y, .ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #114375;
  /* width: 11px; */
}
.ps__thumb-y {
  background-color: #114375;
}


.editorArea {
  position: relative;
  right: 0.9%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* margin-left: 0.5vmax; */
  margin-right: 0.5vmax;
  padding-bottom: 0.5vh;
  /* height: 47vh; */
  width: 99%;
}
.component {
  max-width: 99%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.editorTab {
  /* margin-top: 0.5vmax !important; */
  display: flex;
  width: 98%;
  border-top-left-radius: 3px !important;
  border-top-right-radius: 3px !important;
  flex-direction: row;
}
.editorButton > button {
  max-height: 4vh;
  font-weight: 500;
}
.codePy{
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0.8vmax 0px 1vw;
  color: white;
  border-right: 1px solid white;
  font-weight: 500;
}

.editorTab .codePy:hover {
  color: white;
  cursor: default;
}

.up {
  background-color: #05192d !important;
  margin-top: 0.5vh;
  height: 38px;
}
.up > button {
  
  font-size: 14px;
  padding: 4px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  background-color: #05192d;

  margin-top: 0%;
  margin-bottom: 0px;
  max-height: inherit;
}
.up > button:hover {
  background-color:#114375;
  transition-duration: 1;
}

.up > button:nth-of-type(1) {
  border-top-right-radius: 3px !important;
  margin-right: 0;
  margin-left: auto;
  border-left: 1px solid white;
  padding-right: 10px;
  padding-left: 0px;
}

.down {
  /* margin-bottom: -2%; */
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
  background-color: rgba(243, 242, 242, 0.822);
  /* border: 2px solid black; */
  /* max-height: 4vh !important; */
  flex-direction: row-reverse;
  position: relative;
  top: -1px;
}

.down > button {
  font-size: 12px;
  margin: 0.25vw 0.5vw 0.25vw 0.5vw;
  color: #05192d;
  background-color: #03ef62 !important;
  box-sizing: border-box;
  border: 1px solid rgb(3, 239, 98);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 2px;
  max-height: inherit;
}
.spin{
  background-color: aqua;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.down > button:hover {
  background-color: rgba(3, 239, 98, 0.2) !important;

}
.down > button:disabled{
  background-color: rgba(3, 239, 98, 0.2) !important;
}
.textArea{
  border: 3px solid #05192d;
}
textarea {
  font-size: 12px !important;
  font-weight: 400;
  font-family: monospace !important;
  padding: 0.5vmax;
  width: 98%;
  resize: none !important;
  height: 98%;
  border-radius: 3px !important;

}
.component > textarea {
  width: 97.5%;
  margin: 0vmax 1vw 1vw 1vw;
  border-radius: 8px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.console {
  padding: 2px 8px 2px 8px;
  display: flex;
  align-items: center;
  margin-left: 1vw;
  margin-right: auto;
  border-bottom: 1px solid white;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  color: white;
  background-color: #05192d;
  font-weight: 500;
  font-size: 14px;
  width: 97.5%;

}
.console > span{
  border-right: 1px solid white;
  margin-left: 1%;
  padding-right: 0.7%;
}
.secondComponent {
  height: 160px;
  position: relative;
  right: 0.9%;
  /* left: 0.4%; */
  width: 98.6%;
  /* bottom: 2.5vh !important; */
}
.dojoBot {
  padding: 4px;
  width: 96.7%;
  margin: 1vh 1vw 0.2vh 0.7vw;
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  position: relative;
  left: 0.5%;
}
.bot {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 2px;
  margin-bottom: 1px;
  border-bottom: 1px solid white;
}
.dojoText{
  font-family: monospace;
  font-size: 12px;
}
@media only screen and (max-width: 630px) {
  .up > button:nth-of-type(2) {
    border-top-right-radius: 3px !important;
    margin-left: 0;
    /* margin-left: 70.5vw; */
    border-left: 1px solid white;
    position: absolute;
    right: 20%;
  }
}

@media only screen and (max-width: 408px) {

  .down > button {
    font-size: 12px;
    margin: 1.25vw 0.5vw 0.25vw 0.5vw;
    color: #05192d;
    background-color: #03ef62 !important;
    box-sizing: border-box;
    border: 1px solid rgb(3, 239, 98);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 2px;
    max-height: inherit;
}
  .secondComponent {
    position: relative;
    /* left: 0.76%; */
    bottom:5vh;
  }
  .editorArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /* margin-left: 0.5vmax; */
    margin-right: 0.5vmax;
    padding-bottom: 0.5vh;
    height: 40vh;
    width: 99%;
  }
  .dojoBot {
    padding: 3px;
    width: 96.5%;
    margin: 0vmax 1vmax 1vmax 1vmax;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    position: relative;
}
.up > button:nth-of-type(2) {
  border-top-right-radius: 3px !important;
  margin-left: 0;
  /* margin-left: 70.5vw; */
  border-left: 1px solid white;
  position: absolute;
  right: 21%;
}

}