

@font-face {
  font-family: "SourceSansProRegular";
  src: local("SourceSansProRegular"),
   url("./fonts/Source Sans Pro/SourceSansPro-Regular.ttf") format("truetype");
 }

@font-face {
  font-family: "SourceSansProSemiBold";
  src: local("SourceSansProSemiBold"),
   url("./fonts/Source Sans Pro/SourceSansPro-Semibold.ttf") format("truetype");
 }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Source Sans Pro'
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
